<template>
  <div class="container">
      <h2>First Visit?</h2>
      <p>Please introduce yourself.<br>
      We read together via Zoom, and there is a waiting room.</p>

      <p>If the library is open, and no one else is present, you may introduce yourself <a href="https://zoom.us/j/97584342212?pwd=eHoyZVM4VTBrSzMyOHNqRUs2emlsZz09" target="_blank">in person</a>.<br>
      If we are already reading, leave your info here.</p>

      <p>Why register? Safety.<br>
      If you’re a kid, please introduce your parent! And siblings too!<br>
      Parents like to know what you’re doing, and where you are online, even if it’s a library!<br>
      Parents are welcome to listen to see/hear how this works.</p>

      <form @submit="checkForm" method="POST" action="/submit-welcome.php">
        <div class="form-group required">
            <label for="first-name">First Name</label>
            <input type="text" class="form-control" name="first-name" id="first-name" v-model="name" />
        </div>
        <div class="form-group">
            <label for="last-name">Last Name (optional)</label>
            <input type="text" class="form-control" name="last-name" id="last-name" />
        </div>
        <div class="form-group required">
            <label for="parent-email">Parent Email</label>
            <input type="email" class="form-control" name="parent-email" id="parent-email" v-model="parentEmail" />
        </div>
        <div class="form-group">
            <label for="student-email">Student Email (optional)</label>
            <input type="email" class="form-control" name="student-email" id="student-email" />
        </div>
        <div class="form-group">
            <label for="registering">I am…</label>
              <table>
                <tr><td>
                  <input type="radio" id="student" name="registering" value="student">
                  </td><td>
                  <label for="student">a student registering with a parent's permission</label>
                </td></tr>
                <tr><td>
                  <input type="radio" id="adult" name="registering" value="adult">
                  </td><td>
                  <label for="adult">an adult registering for my kid(s)</label>
                </td></tr>
                <tr><td>
                  <input type="radio" id="other" name="registering" value="other">
                  </td><td>
                  <label for="other">other</label>
                </td></tr>
              </table>
        </div>
        <div class="form-group required">
            <label for="zoom-ids">Name(s) I will see you as on Zoom</label>
            <input type="text" class="form-control" name="zoom-ids" id="zoom-ids" v-model="zoomName" />
        </div>

          <button type="submit" name="submit" id="submit-button">Submit</button>
      </form>

      <p>Email to students will be cc-d to parents.<br>
      Your email will not be shared.<br>
      School-provided emails usually cannot receive mail from those outside school.</p>
  </div>
</template>

<script>
export default {
    name: "Welcome",
    data() {
      return {
        name: null,
        parentEmail: null,
        zoomName: null
      }
    },
    methods: {
      checkForm: function(e) {
        if (this.name && this.parentEmail && this.zoomName) {
          return true;
        }
        const requiredFields = document.querySelectorAll(".required");
        for (let field of requiredFields) {
          if (field.lastChild.value === "") {
            field.classList.add("error");
          } else {
            field.classList.remove("error");
          }
        }
        e.preventDefault();
      }
    }
}
</script>

<style scoped>
h2 {
  font-size: 2rem;
}
p {
  margin-top: 1rem;
  font-size: 1.2rem;
}
form {
  padding: 2rem;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  width: 70%;
  max-width: 500px;
  margin-left: 1rem;
  font-size: 1.2rem;
}
.form-group {
  padding-bottom: 1rem;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
}
label {
  font-weight: bold;
}
table {
  padding-left: .5rem;
}
table tr td label {
  font-weight: normal;
}
input[type=radio] {
  height: 1.2rem;
  width: 1.2rem;
  margin-right: .5rem;
  margin-top: .5rem;
}
.radio input {
  margin-right: .3rem;
}
.radio label {
  font-weight: normal;
  margin-left: .2rem;
}
.form-control {
  height: 2rem;
  border: 1px solid #ddd;
  border-radius: 0;
  padding-left: 5px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  margin-top: 3px;
}
.form-control:focus {
  outline: none;
}
#submit-button {
  border: 2px solid #407137;
  border-radius: 8px;
  background: #407137;
  color: white;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  width: 50%;
  margin-top: 1rem;
}
#submit-button:hover {
  background: white;
  color: #407137;
  cursor: pointer;
}
#submit-button:focus {
  outline: none;
}
.form-group.error .form-control {
  outline: 1px dashed red;
}
.form-group.error::after {
  content: "This field is required.";
  color: red;
  font-size: .8rem;
  margin-top: .2rem;
}
</style>